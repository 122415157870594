/* Base styles for .contact-image-section */
.contact-image-section {
  position: relative;
  height: 600px; /* Default height */
  background-image: url(../../images/contactus-min.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #001e3f; /* Text color */
}

/* Heading styles */
.contact-image-section h2 {
  font-weight: 800;
  position: relative;
  margin-bottom: 120px; /* Default margin */
  color: white; /* Heading text color */
}

/* Responsive styles */
@media (max-width: 768px) {
  /* Adjust height for smaller screens */
  .contact-image-section {
    height: 400px; /* Adjust height as needed */
  }

  /* Adjust margin for smaller screens */
  .contact-image-section h2 {
    margin-bottom: 60px; /* Adjust margin as needed */
  }
}

@media (max-width: 480px) {
  /* Adjust height for smaller screens */
  .contact-image-section {
    height: 300px; /* Adjust height for 480px screens */
  }

  /* Adjust margin for smaller screens */
  .contact-image-section h2 {
    margin-bottom: 40px; /* Adjust margin for 480px screens */
    font-size: 24px; /* Adjust font size for 480px screens */
  }
}