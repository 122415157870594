/* WeCare.css */

/* Styles for the container */
.we-care-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Add padding to the container */
  margin: 20px auto;
  
}

/* Styles for the text content */
.text-content {
  text-align: center;
  margin-bottom: 20px;
  padding: 30px;
  opacity: 0; /* Initially hide the text content */
  animation: fadeIn 1s ease forwards; /* Apply the fadeIn animation */
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


/* Styles for the heading */
.text-content h2 {
  font-size: 2.3rem; /* Set the font size of the heading */
  font-weight: 700;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;
  color: #01579e;
}

/* Styles for the paragraph */
.text-content p {
  font-size: 1rem; /* Set the font size of the paragraph */
  color: black;
  font-family: 'Poppins', sans-serif;

}

/* Styles for the image content */
.image-content {
  width: 100%; /* Set the width of the image to 100% */
  max-width: 300px; /* Set the maximum width of the image */
  padding: 10px;
 
}
.image-content img{
  max-width: 100%;
  height: 600px;
  border-radius: 10px;
}

/* Responsive styles */
@media (min-width: 768px) {
  /* Adjust the layout for medium and large screens */
  .we-care-container {
    flex-direction: row;
  }

  .text-content {
    width: 50%; /* Occupy 50% of the container width */
    margin-right: 20px;
    text-align: left; /* Align text to the left */
  }

  .image-content {
    width: 50%; /* Occupy 50% of the container width */
    max-width: none; /* Remove the maximum width for larger screens */
  }
}

@media (min-width: 576px) {
  /* Adjust the layout for small screens */
  .we-care-container {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .text-content h2 {
    font-size: 2.1rem; /* Decrease the font size of the heading */
  }

  .text-content p {
    font-size: 1.1rem; /* Decrease the font size of the paragraph */
  }
  
  .image-content img {
    width: 100%; /* Set the width of the image to 100% */
    max-width: 100%; /* Ensure the image does not exceed its container */
  }

}
@media (max-width: 480px) {
  /* Adjust the layout for small screens */
  .we-care-container {
    padding: 10px; /* Reduce padding for smaller screens */
  }

  .text-content h2 {
    font-size: 1.3rem; /* Decrease the font size of the heading */
  }

  .text-content p {
    font-size: 1rem; /* Decrease the font size of the paragraph */
  }
  
  .image-content img {
    width: 100%; /* Set the width of the image to 100% */
    max-width: 100%; /* Ensure the image does not exceed its container */
  }

}


