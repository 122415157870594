html {
    scroll-behavior: smooth;
  }
  
  #progress {
    position: fixed;
    bottom: 20px;
    right: 20px;
    height: 70px;
    width: 70px;
    display: none;
    place-items: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 9999;
  }
  
  #progress:hover {
    background-color: #1A76D1;
  }
  
  #progress-value {
    display: block;
    height: calc(100% - 15px);
    width: calc(100% - 15px);
    background-color: #fff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 35px;
    color: #1A76D1;
    transition: transform 1s ease;
  }
  
  #progress-value:hover {
    background-color: #1A76D1;
    color: #fff;
    transform: rotate(180deg);
  }
  
  @media (max-width: 767px) {
    #progress {
      bottom: 10px;
      right: 10px;
    }
  }
  
  