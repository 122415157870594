/* Base styles */
.custom-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.custom-text-center {
  text-align: center;
  font-weight: 700;
  color: #01759E;
}

.arrow-icons {
  position: absolute;
  left: 0px;
  font-size: 24px; /* Adjust icon size */
  color: #fff; /* Black color for icons */
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background-color: #01579e;
}

.custom-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; /* Add gap between cards */
}

.custom-service-card {
  background-color: #01759E;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 0px;
  height: 580px;
  margin: 10px;
  text-align: left; /* Align text to the left */
  transition: transform 0.3s ease-in-out;
}

.custom-service-card:hover {
  transform: translateY(-5px);
}

.custom-service-card-image {
  width: 100%;
  height: auto;
  border-radius: 0px;
  margin-bottom: 20px;
}

.custom-service-card-title {
  font-size: 2rem;
  color: #fff;
  margin: 5px;
  margin-bottom: 10px;
}

.custom-service-card-description {
  font-size: 1rem;
  color: #fff;
  line-height: 1.5;
}

/* Styles for the prev and next arrows */
.slick-prev,
.slick-next {
  font-size: 24px; /* Adjust the font size of the icons */
  color: #01759E; /* Change the color of the icons */
  z-index: 1; /* Ensure the arrows appear above the slider */
}

.slick-prev {
  left: 20px; /* Adjust the left position */
}

.slick-next {
  right: 20px; /* Adjust the right position */
}

.slick-prev:hover,
.slick-next:hover {
  color: #555; /* Change the color on hover */
}

/* Media queries for responsiveness */

@media (max-width: 992px) {
  .custom-service-card {
    height: auto; /* Change height to auto for smaller screens */
  }

  .custom-service-card-image {
    border-radius: 10px; /* Reset border radius */
  }
}

@media (max-width: 768px) {
  .custom-container {
    padding: 0 10px; /* Reduce padding for smaller screens */
  }

  .arrow-icons {
    width: 25px;
    height: 25px;
    font-size: 20px;
  }

  .slick-prev,
  .slick-next {
    font-size: 20px; /* Adjust the font size of the icons */
  }
}

@media (max-width: 480px) {
  .custom-container {
    padding: 0 10px; /* Reduce padding for smaller screens */
  }

  .arrow-icons {
    width: 25px;
    height: 25px;
    font-size: 20px;
  }

  .slick-prev,
  .slick-next {
    font-size: 20px; /* Adjust the font size of the icons */
  }
}


