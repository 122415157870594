/* localcardSection.css */

.localcard-section {
    display: grid;
    grid-template-columns: 40% 60%; /* Adjust column widths */
    justify-content: space-between;
    align-items: center;
    max-width: 1170px;
    margin: 20px auto;
  }
  
  .localcard-image {
    /* 60% width */
    margin-right: 20px; /* Adjust margin as needed */
    transition: transform 0.3s ease;
  }
  
  .localcard-image img {
    max-width: 100%;
    height: auto;
  }
  
  .localcard-image img:hover {
    transform: scale(0.8); /* Increase scale to zoom in */
  }
  
  .localcard-content {
    
  }
  
  .localcard-content h2 {
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #01579e;
    font-family: 'Poppins', sans-serif;

  }
  .localcard-content h4{
    color: black;
    font-size: 17px;
    font-family: 'Poppins', sans-serif;

  }
  
  .localcard-content p {
    font-size: 16px;
    line-height: 1.6;
    color: #7a7a7a;
    font-family: 'Poppins', sans-serif;

  }
  
  /* Responsive layout adjustments */
  @media (max-width: 768px) {
    .localcard-section {
      grid-template-columns: 100%; /* Single column layout on smaller screens */
    }
  
    .localcard-image {
      margin-right: 0; /* Remove right margin on smaller screens */
      margin-bottom: 20px; /* Add bottom margin for spacing */
    }
  }
  