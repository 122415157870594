/* Footer.css */

/* footer down */
*{
  font-family: 'Poppins', sans-serif;

  
  }
  .footer {
      background-color:  rgba(211, 211, 211, 0.44313725490196076);
      color: #fff;
      padding: 30px 20px;
    }
    
    .footer-content {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 20px;
    }
    
    .footer-column {
      margin-bottom: 20px;
    }
   
    
    .footer-column h3 {
      font-size: 18px;
      font-weight: 800;
      margin-bottom: 10px;
      margin-left: 100px;
      font-family: 'Poppins', sans-serif;
      color: black;
      
    }
    .footer-column p{
      color: black;
      font-size: 17px;
      font-family: 'Poppins', sans-serif;
    }

    .footer-column a{
        text-decoration: none;
        color: black;
        font-family: 'Poppins', sans-serif;
    }
    .footer-column .icons-footer{
      font-size: 26px;
    }
    
    .social-icons a {
      color: #001e3f;
      font-size: 50px;
      margin-right: 20px;
    
    }
    .social-icons i{
      color: #001e3f;
      padding: 20px;
    }

    .footer-bottom {
      margin-top: 20px;
      font-size: 18px;
    }
    .footer-bottom p{
      color: black;
      text-align: center;
      font-size: 18px;
      font-family: 'Poppins', sans-serif;
    }
    

    .footer-separator {
      border-bottom: 1px solid #999;
      margin-bottom: 10px;
    }
  
    /* Parallax */
    .parallax-footer {
      position: relative;
      overflow: hidden;
    }
  
    .parallax-footer::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    
    .parallax-footer .footer-content {
      position: relative;
      z-index: 1;
    }
    
  
    /* Responsive styles */
  @media screen and (max-width: 768px) {
    .footer-content {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    
    .footer-column {
      width: 100%;
      margin-bottom: 20px;
    }
    
    .social-icons {
      display: flex;
      justify-content: center;
      margin-top: 20px;
    }
    
    .footer-separator {
      display: none;
    }
  }

  @media screen and (max-width: 480px) {
    .footer-content {
      display: flex;
      flex-wrap: wrap;
      align-items: start;
    }
    
    .footer-column {
      width: 100%;
      margin-bottom: 20px;
      text-align: start;
    }
  .footer-column h3{
      margin-left: 20px;
  }
  
    .footer-column p {
      color: black;
      font-size: 15px;
      font-family: 'Poppins', sans-serif;
      text-align: start;
    }
    
    .social-icons {
      display: flex;
      justify-content: start;
      margin-top: 20px;
    }
    
    .footer-separator {
      display: none;
    }
    
    .footer-bottom p {
      font-size: 15px;
    }
  }
  