.international-image-section {
   position: relative;
   margin: 0 auto;
   max-width: 100%; /* Ensures the section does not exceed the width of its container */
   overflow: hidden; /* Prevents horizontal scrolling on smaller screens */
 }
 
 .international-image {
   width: 100%; /* Ensures the image fills the width of its container */
   height: auto; /* Allows the image height to scale proportionally with the width */
 }
 
 /* Responsive styles */
 @media screen and (max-width: 768px) {
   .international-image-section {
       height: auto; /* Allow the section height to adjust based on content */
   }
 
   .international-image {
       max-height: 480px; /* Adjust the maximum height for smaller screens */
   }
 }
 
 @media screen and (max-width: 480px) {
   .international-image-section {
       height: auto; /* Allow the section height to adjust based on content */
   }
 
   .international-image {
       max-height: 360px; /* Further adjust the maximum height for even smaller screens */
   }
 }
 