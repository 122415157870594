.procedure-image-section {
    
}

.procedure-image {
  width: 100%; /* Ensures the image does not exceed the width of its container */
  height: 640px; /* Allows the image height to scale proportionally with the width */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .procedure-image {
    max-width: 100%; /* Adjusts image size for smaller screens */
  }
}
