.local-text-section {
  margin: 20px auto;
  position: relative;
  height: 120px;
  background-color: #01579e;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #001e3f;
}

.local-text-section h2 {
  position: relative;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-size: 30px; /* Set initial font size */
  color: #fff;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .local-text-section {
      height: 80px; /* Allow the section height to adjust based on content */
  }

  .local-text-section h2 {
      font-size: 20px; /* Adjust font size for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .local-text-section {
      height: 80px; /* Allow the section height to adjust based on content */
  }

  .local-text-section h2 {
      font-size: 20px; /* Further adjust font size for even smaller screens */
  }
}
