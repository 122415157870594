
.tourism-hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px;
  background-color: #fff;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.tourism-image-content img {
  width: 100%;
  max-width: 600px; /* Limit the image width */
  height: auto;
  border-radius: 8px;
}

.tourism-text-content {
  max-width: 1200px;
  text-align: center;
  margin-top: 20px;
}

.tourism-heading {
  font-size: 24px;
  color: #01579e;
  font-family: 'Poppins', sans-serif;
}

.tourism-paragraph {
  color: grey;
  font-size: 18px;
  line-height: 1.5;
  font-family: 'Poppins', sans-serif;
}

.tourism-hero-container.visible {
  opacity: 1;
  transform: translateY(0);
}


/* Media Queries for Responsive Design */

/* For smaller screens */
@media only screen and (max-width: 768px) {
  .tourism-hero-container {
    padding: 30px;
  }

  .tourism-image-content img {
    max-width: 100%;
  }

  .tourism-text-content {
    max-width: 100%;
  }
  .tourism-heading{
    font-size: 20px;
  }
  .tourism-paragraph{
    font-size: 17px;
  }
}
