/* CSS for .tourism-image-section */

.tourism-image-section {
   position: relative;
   margin: 0 auto;
   height: 600px; /* Initial height for the image section */
   background-image: url(../../images/medicaltourism.jpg); /* Background image */
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #001e3f; /* Text color */
 }
 
 .tourism-image-section h2 {
   font-family: 'Poppins', sans-serif;
   font-weight: 800;
   font-size: 30px;
   color: #fff; /* Heading text color */
 }

 
 
 /* Media queries for responsiveness */
 @media screen and (max-width: 768px) {
   .tourism-image-section {
     height: 400px; /* Decrease height for smaller screens */
   }
 }
 
 @media screen and (max-width: 480px) {
   .tourism-image-section {
     height: 300px; /* Further decrease height for even smaller screens */
   }
 }
 