/* TestSection.css */

* {
  font-family: 'Montserrat', sans-serif;
}

.test-section {
  width: 100vw;
  height: 500px;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.5);
  object-fit: cover;
}

.section-center {
  margin: 0 auto;
  margin-top: 4rem;
  width: 80vw;
  height: 450px;
  max-width: 1200px;
  text-align: center;
  position: relative;
  display: flex;
  overflow: hidden;
  transition: transform 0.5s ease;
}

.test-section .activeSlide {
  opacity: 1;
  transform: translateX(0);
}

.test-section .lastSlide {
  opacity: 0;
  transform: translateX(-100%);
}

.test-section .nextSlide {
  opacity: 0;
  transform: translateX(100%);
}

article {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.3s linear;
}

article.activeSlide {
  opacity: 1;
  transform: translateX(0);
}

article.lastSlide {
  transform: translateX(-100%);
}

article.nextSlide {
  transform: translateX(100%);
}

.person_img {
  border-radius: 50%;
  margin-bottom: 1rem;
  width: 150px;
  height: 150px;
  object-fit: cover;
  border: 4px solid hsl(210, 31%, 80%);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

article h4 {
  margin-bottom: 50px;
  color: #fff;
}

.title {
  text-transform: capitalize;
  margin-top: 2rem;
  color: #fff;
}

.title h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 25px;
  margin-top: 100px;
  letter-spacing: 2px;
  border-right: 5px solid;
  width: 100%;
  overflow: hidden;
  color: #fff;
}

.text {
  max-width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
  line-height: 32px;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
}

.prev,
.next {
  position: absolute;
  top: 80px;
  transform: translateY(-50%);
  color: #030e90;
  background-color: #fff;
  display: grid;
  place-items: center;
  border-color: transparent;
  font-size: 55px;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  cursor: pointer;
  transition: all 0.3s linear;
}

.prev:hover,
.next:hover {
  background: white;
  color: #030e90;
}

.prev {
  left: 0;
  width: 2rem;
  height: 1.4em;
  font-size: 1.5rem;
}

.next {
  right: 8px;
  width: 1.7rem;
  height: 1.6rem;
  font-size: 1.1rem;
}

.prev svg {
  margin-right: -8px;
}

.next svg {
  margin-left: -8px;
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .test-section{
    height: 800px;
  }
  .section-center {
    width: 90vw;
    margin-top: 3rem;
  
  }
  
  article h4 {
    margin-bottom: 30px;
  }

  .title h2{
    text-align: center;
  }
  
  .text {
    font-size: 16px;
  }
  
  .prev,
  .next {
    top: 60px;
    width: 30px;
    height: 30px;
    font-size: 40px;
  }
  
  .prev {
    left: -10px;
  }
  
  .next {
    right: -10px;
  }
}
