/* Default styles */
.servicetext-section {
   position: relative;
   margin: 20px auto;
   height: 120px;
   background-color: #01579e;
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #001e3f;
 }
 
 .servicetext-section h2 {
   font-weight: 800;
   font-size: 30px;
   color: #fff;
   font-family: 'Poppins', sans-serif;
 }
 
 /* Responsive styles */
 @media screen and (max-width: 768px) {
   .servicetext-section{
      height: 80px;
   }
   .servicetext-section h2 {
     font-size: 24px; /* Adjust font size for smaller screens */
   }
 }
 
 @media screen and (max-width: 480px) {
   .servicetext-section{
      height: 80px;
   }
   .servicetext-section h2 {
     font-size: 20px; /* Adjust font size for even smaller screens */
   }
 }
 