/* TreatmentOb.css */

/* Base font family */
* {
  font-family: 'Poppins', sans-serif;
}

/* Service section container */
#treatment-services {
  background: #f8f9fa;
  padding: 3rem 0;
}

/* Colored heading */

/* Colored heading line */


/* Treatment service item */
.treatment-service-item {
  align-items: start;
  width: 100%;
  border-radius: 8px;
  padding: 40px;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

/* Treatment service icon */
.treatment-service-icon {
  width: 80px;
  justify-content: center;
  color: #01759E;
  margin-bottom: 1rem;
  font-size: 3rem;
}

/* Treatment service title */
.treatment-service-title {
  font-size: 1.5rem;
  margin-bottom: 0.1rem;
  color: #01759E;
  font-weight: 700;
  border-bottom: 2px solid #01759E;
  
}

/* Treatment service description */
.treatment-service-description {
  text-align: left;
  margin: 10px;
  font-size: 18px;
  color: #7a7a7a;
  font-family: 'Poppins', sans-serif;
}

.show-more-button{
  border-radius: 8px;
  padding: 10px;
  text-decoration: none;
  background-color: #01579e;
  transition: 0.3s all ease-in;
  color: #fff;
}
.show-more-button:hover{
  background-color: #01759E;
  color: #fff;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  /* Adjustments for smaller screens */
  .treatment-service-item {
      padding: 20px;
  }

  .treatment-service-icon {
      font-size: 2.5rem;
  }

  .treatment-service-title {
      font-size: 1.3rem;
  }

  .treatment-service-description {
      font-size: 18px;
  }
}

@media screen and (max-width: 480px) {
  /* Further adjustments for even smaller screens */
  .treatment-service-icon {
      width: 60px;
  }

  .treatment-service-title {
      font-size: 1.2rem;
  }

  .treatment-service-description {
      font-size: 16px;
  }
}
