/* Base styles for .single-image-section */
.single-image-section {
  margin: 0 auto; /* Center the section horizontally */
}

.animated-section {
  animation-name: fadeIn;
  animation-duration: 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Responsive image styles */
.responsive-image {
  width: 100%; /* Make the image responsive to the width of its container */
  height: auto; /* Allow the height to scale proportionally with the width */
  max-height: 640px; /* Set a maximum height to prevent the image from becoming too tall */
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .responsive-image {
    max-height: 480px; /* Adjust max-height for smaller screens */
  }
}

@media (max-width: 480px) {
  .responsive-image {
    max-height: 320px; /* Further adjust max-height for even smaller screens */
  }
}
