/* Container for the whole form */
.contact-form-container {
  display: flex;
  flex-direction: column; /* Change to column layout on small screens */
  margin: 40px auto;
  padding: 20px;
}

/* Container for the map */
.map-container {
  margin-bottom: 20px; /* Add margin at the bottom for spacing */
}

/* Styling for the map iframe */
.map-container iframe {
  width: 100%;
  height: 450px;
  border: 0;
}

/* Container for the email form */
.email-form-container {
  margin-bottom: 20px; /* Add margin at the bottom for spacing */
}

/* Title for the form */
.email-form-container h2 {
  color: #01579e;
  font-weight: 800;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

/* .email-form-container h2::after {
  content: '';
  background-color: #03045e;
  display: block;
  height: 3px;
  width: 50px;
  margin: 20px auto 5px;
} */

/* Success message styling */
.success-message {
  color: green;
  margin-bottom: 10px;
}

/* Error message styling */
.error-message {
  color: red;
  margin-bottom: 10px;
}

/* Form styling */
form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Input field styling */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: none; /* Hide labels */
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-family: 'Poppins', sans-serif;

}

textarea {
  resize: vertical; /* Allow vertical resizing */
  min-height: 100px; /* Set a minimum height for textarea */
}

/* Error message styling for input fields */
.error-message {
  color: red;
  font-size: 15px;
  
  border-radius: 5px;
  padding: 5px 10px;
}



/* Button styling */
button[type="submit"] {
  background-color: #01579e;
  border-radius: 100px;
  box-shadow: rgba(72, 131, 200, 0.2) 0 -25px 18px -14px inset,rgba(40, 134, 216, 0.15) 0 1px 2px,rgba(46, 139, 231, 0.15) 0 2px 4px,rgba(43, 116, 211, 0.15) 0 4px 8px,rgba(63, 167, 237, 0.15) 0 8px 16px,rgba(48, 169, 205, 0.15) 0 16px 32px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: CerebriSans-Regular,-apple-system,system-ui,Roboto,sans-serif;
  padding: 7px 20px;
  text-align: center;
  text-decoration: none;
  transition: all 250ms;
  border: 0;
  font-size: 16px;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

}

button[type="submit"]:hover {
  box-shadow: rgba(47, 112, 173, 0.35) 0 -25px 18px -14px inset,rgba(48, 115, 210, 0.25) 0 1px 2px,rgba(50, 210, 210, 0.25) 0 2px 4px,rgba(44, 132, 187, 0.25) 0 4px 8px,rgba(49, 146, 215, 0.25) 0 8px 16px,rgba(36, 118, 162, 0.25) 0 16px 32px;
  transform: scale(1.05) rotate(-1deg);
}




/* Responsive Design */
@media screen and (min-width: 768px) {
  /* For tablets and larger screens */
  .contact-form-container {
    flex-direction: row; /* Change back to row layout */
  }
  
  .map-container,
  .email-form-container {
    flex: 1; /* Equal width for both containers */
  }
  
  .map-container {
    margin-right: 20px; /* Add margin between map and form */
  }
}
