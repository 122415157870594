/* src/Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: #007d9d;
  padding: 20px;
  border-radius: 8px;
  position: relative;
  width: 80%;
  max-width: 1200px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.modal-header h2 {
  text-align: center;
  margin-left: 200px;
  font-size: 1.5rem;
  width: 60%;
  max-width: 800px;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.8rem;
  cursor: pointer;
  color: white;
}

.close-button:hover {
  color: #fdba11;
  transition: 0.3s ease-in;
}

.modal-body {
  margin-top: 20px;
}

.modal-date-time-location {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-date-time-location p {
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  margin: 0 10px;
  color: #fff;
}

.modal-date-time-location p svg {
  margin-right: 5px;
}

.modal-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.modal-table th {
  border: 1px solid #fdba11;
  padding: 8px;
  text-align: left;
  color: #007d9d;
  font-weight: 800;
  background-color: #f2f2f2;
}

.modal-table td {
  width: 800px;
  color: #fff;
  border: 1px solid #fdba11;
  padding: 8px;
  text-align: left;
}

.doctor-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin-right: 10px;
}

.modal-rsvp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
  margin-bottom: 20px;
  background-color: #fff;
}

.modal-rsvp span {
  color: #007d9d;
  font-weight: 600;
}

.rsvp-text {
  flex-grow: 1;
  margin: 10px;
}

.rsvp-text strong {
  font-size: 20px;
  color: #fdba11;
}

.rsvp-logo {
  width: 100px;
  height: auto;
  margin-left: 20px;
}

.modal-footer {
  text-align: center;
  font-size: 0.9rem;
  color: #fdba11;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    padding: 10px;
  }

  .modal-header h2 {
    margin-left: 0;
    font-size: 1.0rem;
    width: 100%;
  }

  .modal-date-time-location {
    flex-direction: column;
    align-items: flex-start;
  }

  .modal-date-time-location p {
    margin: 5px 0;
  }

  .modal-table th, .modal-table td {
    padding: 5px;
  }

  .doctor-image {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }

  .modal-rsvp {
    flex-direction: column;
    align-items: flex-start;
  }

  .rsvp-logo {
    width: 80px;
    margin-left: 0;
    margin-top: 10px;
  }
}

@media (max-width: 480px) {
  .modal-header h2 {
    font-size: 0.8rem;
  }

  .close-button {
    font-size: 1.5rem;
  }

  .modal-date-time-location p {
    font-size: 1rem;
  }

  .modal-table th, .modal-table td {
    font-size: 0.9rem;
  }

  .rsvp-text strong {
    font-size: 18px;
  }
}
