.oncology-container{
  display: flex;
  justify-content: center;
  padding: 10px;
  background: #01579e;
  font-family: 'Poppins', sans-serif;
  color: #333;
}

.heading2{
  font-size: 22px;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  color: #01579e;
}
.heading-li{
  margin: 3px;
  font-size: 18px;
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #ccc; /* Adjust color as needed */
  margin: 20px 0; /* Adjust margin as needed */
}

@media (min-width: 600px) {
  .oncology-container{
    padding: 60px 10px;
  }
}
.oncology-panel h1 {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 30px;
  font-weight: 700;
  color: #01579e;
}
.oncology-panel p {
  margin-top: 20px;
  margin-bottom: 0;
  padding: 0;
  font-size: 18px;
  line-height: 1.5;
}
.oncology-tabs {
  display: flex;
  flex-wrap: wrap;
  max-width: 1200px;
  background: #e5e5e5;
  box-shadow: 0 48px 80px -32px rgba(0,0,0,0.3);
}
.oncology-input {
  position: absolute;
  opacity: 0;
}
.oncology-label {
  padding: 20px 30px;
  background: #e5e5e5;
  cursor: pointer;
  font-weight: bold;
  font-size: 18px;
  color: #7f7f7f;
  transition: background 0.1s, color 0.1s;

}

.oncology-label:hover {
  background: #d8d8d8;
}
.oncology-label:active {
  background: #ccc;
}
.oncology-input:focus + .oncology-label {
  z-index: 1;
}
.oncology-input:checked + .oncology-label {
  background: #fff;
  color: #000;
}
@media (min-width: 600px) {
  .oncology-label {
    width: auto;
  }
}
.oncology-panel {
  display: none;
  padding: 20px 30px 30px;
  background: #fff;
}
@media (min-width: 600px) {
  .oncology-panel {
    order: 99;
  }
}
.oncology-input:checked + .oncology-label + .oncology-panel {
  display: block;
}
