/* Base styles for .container */
.container {
  padding: 50px 0;
}

/* Heading styles */
.objectives-heading {
  font-size: 2.5rem;
  color: #fff;
}

/* Objective card carousel styles */
.objective-card-carousel {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* Individual objective card styles */
.objective-card {
  width: 300px;
  margin: 20px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.objective-card:hover {
  transform: translateY(-5px);
}

.objective-card-body {
  text-align: center;
}

/* Objective card title styles */
.objective-card-title {
  font-size: 1.5rem;
  color: #01579E;
  font-family: 'Poppins', sans-serif;
}

/* Objective card text styles */
.objective-card-text {
  font-size: 1rem;
  color: #7a7a7a;
  font-family: 'Poppins', sans-serif;
}

/* Objective icon styles */
.objectives-icon {
  margin-right: 10px;
  color: #1a76d1;
}

/* Responsive font sizes */
@media (max-width: 768px) {
  .objectives-heading {
    font-size: 2rem; /* Adjust font size for smaller screens */
  }

  .objective-card {
    width: calc(50% - 40px); /* Adjust card width for smaller screens */
  }

  .objective-card-title {
    font-size: 1.3rem; /* Adjust font size for smaller screens */
  }

  .objective-card-text {
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
}

@media (max-width: 480px) {
  .objectives-heading {
    font-size: 1.5rem; /* Further adjust font size for even smaller screens */
  }

  .objective-card {
    width: calc(100% - 40px); /* Adjust card width for even smaller screens */
  }

  .objective-card-title {
    font-size: 1.2rem; /* Further adjust font size for even smaller screens */
  }

  .objective-card-text {
    font-size: 0.8rem; /* Further adjust font size for even smaller screens */
  }
}
