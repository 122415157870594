/* Base styles for .abouttext-section */
.abouttext-section {
   position: relative;
   margin: 20px auto;
   height: 120px; /* Default height */
   background-color: #01579e;
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #001e3f;
 }
 
 /* Heading styles */
 .abouttext-section h2 {
   font-weight: 800;
   font-size: 30px; /* Default font size */
   color: #fff;
   font-family: 'Poppins', sans-serif;
 }
 
 /* Responsive styles */
 @media (max-width: 768px) {
   /* Adjust height for smaller screens */
   .abouttext-section {
     height: 80px; /* Adjust height as needed */
   }
 
   /* Adjust font size for smaller screens */
   .abouttext-section h2 {
     font-size: 24px; /* Adjust font size as needed */
   }
 }
 
 @media (max-width: 480px) {
   /* Further adjustments for even smaller screens */
   .abouttext-section {
     height: 60px; /* Adjust height for smaller screens */
   }
 
   .abouttext-section h2 {
     font-size: 20px; /* Further adjust font size for smaller screens */
   }
 }
 