/* App.css */
.tourism-service-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 50px;
    background-color: #f5f7f9;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .tourism-service-container.animated {
    opacity: 1;
    transform: translateY(0);
  }
  
  .tourism-service-image {
    width: 300px;
    height: auto;
    border-radius: 8px;
  }
  
  .tourism-service-text {
    max-width: 800px;
    font-size: 18px;
    line-height: 1.5;
  }
  
  .tourism-service-text h2 {
    font-size: 24px;
    color: #01579e;
    font-family: 'Poppins', sans-serif;
  }
  
  .tourism-service-text p {
    color: grey;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
  }
  
  /* Animation */
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-50px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  .animated {
    animation: slideIn 1s ease forwards;
  }
  
  /* Media Queries for Responsive Design */
  
  /* For smaller screens */
  @media only screen and (max-width: 768px) {
    .tourism-service-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .tourism-service-image {
      margin-bottom: 20px;
    }
    .tourism-service-text h2{
      font-size: 16px;
    }
    .tourism-service-text p{
      font-size: 17px;
    }
  }
  
  /* For larger screens */
  @media only screen and (min-width: 1200px) {
    .tourism-service-container {
      padding: 100px;
    }
  }
  
  @media only screen and (max-width: 480px) {
    .tourism-service-container {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  
    .tourism-service-image {
      margin-bottom: 20px;
      width: 200px;
    }
  }