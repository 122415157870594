/* HeroSection.css */

/* Common styles */
* {
  font-family: 'Times New Roman', Times, serif;
}

.info-section {
  padding: 50px 0;
  text-align: center;
}

.info-parallax {
  height: 350px;
  opacity: 1.2;
}

.container {
  max-width: 800px;
  margin: 0 auto;
}

.heading {
  font-size: 36px;
  margin-bottom: 20px;
  color: #01579e;
  font-family: 'Poppins', sans-serif;
}

.paragraph {
  font-size: 23px;
  margin-bottom: 30px;
  color: #01579e;
  font-family: 'Poppins', sans-serif;
}

.read-more {
  background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex-shrink: 0;
  font-family: "Inter UI","SF Pro Display",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
  font-size: 16px;
  font-weight: 500;
  padding: 10px ;
  height: 22rem;
  text-align: center;
  text-decoration: none;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all .5s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}



.read-more:hover{
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
  transition-duration: .1s;
}




.read-more:hover {
  background-color: #0056b3; /* Change background color on hover */
  transform: scale(1.1); /* Optional: Add a scale effect on hover */
}



/* Responsive styles */
@media screen and (max-width: 768px) {
  .heading {
    font-size: 20px;
  }
  
  .paragraph {
    font-size: 15px;
  }
  
  .read-more {
    font-size: 15px;
  }
}
