.carousel-caption {
  background-color: #1A76D1; /* Semi-transparent background */
    color: white; /* Text color */
    padding: 20px; /* Padding around the caption text */
    position: absolute;
    bottom: 0; /* Position the caption at the bottom of the carousel */
    left: 0;
    right: 0;
  }
  
  .carousel-caption h3 {
    font-size: 20px; /* Heading font size */
    font-family: 'Poppins', sans-serif;

  }
  
  .carousel-caption p {
    font-size: 16px; /* Paragraph font size */
    font-family: 'Poppins', sans-serif;

  }

  .carousel-item img {
    width: 100%; /* Ensure the image takes up the full width of its container */
    height: auto; /* Maintain aspect ratio while scaling */
  }


  
  
  /* Media queries for responsiveness */
  @media (max-width: 768px) {
    .carousel-caption {
      padding: 10px; /* Reduce padding on smaller screens */
    }
  
    .carousel-caption h3 {
      font-size: 20px; /* Decrease heading font size on smaller screens */
    }
  
    .carousel-caption p {
      font-size: 10px; /* Decrease paragraph font size on smaller screens */
    }
    .carousel-item img {
      width: 100%; /* Ensure the image takes up the full width of its container */
      height: auto; /* Maintain aspect ratio while scaling */
    }
  }
  
  @media (max-width: 576px) {
    .carousel-caption {
      padding: 5px; /* Further reduce padding on even smaller screens */
    }
  
    .carousel-caption h3 {
      font-size: 18px; /* Further decrease heading font size on smaller screens */
    }
  
    .carousel-caption p {
      font-size: 1px; /* Further decrease paragraph font size on smaller screens */
    }
    .carousel-item img {
      width: 50%; /* Ensure the image takes up the full width of its container */
      height: auto; /* Maintain aspect ratio while scaling */
    }
  }
  
  @media (max-width: 480px) {
    .carousel-caption {
      padding: 5px; /* Further reduce padding on even smaller screens */
      height: auto;
    }
  
    .carousel-caption h3 {
      font-size: 15px; /* Further decrease heading font size on smaller screens */
    }
  
  
    .carousel-item img {
      width: 50%; /* Ensure the image takes up the full width of its container */
      height: auto; /* Maintain aspect ratio while scaling */
    }
  }


 