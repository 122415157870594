.text-image-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #2e79b4;
}

.text-image-container {
  flex: 1;
  margin: 0 0px;
}

.service-image {
  max-width: 100%;
  height: auto;
}

.text-image-description {
  flex: 1;
  margin: 20px;
  text-align: left;
  opacity: 0; /* Initially set opacity to 0 for the fade-in effect */
  animation: fadeIn 1.5s ease forwards; /* Animation name, duration, timing function, and fill mode */
}

.text-image-description h2 {
  font-size: 28px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 10px;
  font-family: 'Poppins', sans-serif;

}

.text-image-description p {
  font-size: 16px;
  color: #fff;
  font-family: 'Poppins', sans-serif;

}

/* Keyframes for fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Media query for responsiveness */
@media screen and (max-width: 768px) {
  .text-image-container,
  .text-image-description {
    flex: 0 0 100%; /* Full width on smaller screens */
    margin: 0px 0; /* Adjust margin for spacing */
    text-align: center;
  }
  .text-image-description h2{
    font-size: 20px;
  }
  .text-image-description p{
    font-size: 15px;
  }
}

