/* Service.css */

/* Service section */
#services {
  background-image: url(../../images//100.jpg);
 
}

/* Title styling */
.colored-heading {
  color: #01579e;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 50px;
  font-family: 'Roboto', sans-serif;
}

.colored-heading::after{
  content: '';
  background-color: #01579e;
  display: block;
  height: 3px;
  width: 50px;
  margin: 20px auto 5px;
  
}

/* Service item */
.service-item {
  text-align: center;
  margin-bottom: 50px;
}

/* Service icon */
.service-icon {
  font-size: 48px;
  color: #007bff;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;
}

.service-item:hover .service-icon {
  transform: scale(1.1);
}

/* Service title */
.service-title {
  font-size: 20px;
  font-family: 'Roboto', sans-serif;
  color: #333;
  margin-bottom: 20px;
}

/* Learn More button */
.learn-more-button {
  font-size: 16px;
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  color: #fff;
  font-family: 'Roboto', sans-serif;
  transition: background-color 0.3s ease-in-out;
}

.learn-more-button:hover {
  background-color: #0056b3;
}

/* Modal title */
.modal-title {
  font-size: 28px;
  font-weight: bold;
  color: #333;
}

/* Modal body */
.modal-body {
  font-size: 16px;
  color: #555;
}

/* Modal close button */
.modal-footer .btn-secondary {
  background-color: #ccc;
  color: #333;
  border: none;
  transition: background-color 0.3s ease-in-out;
}

.modal-footer .btn-secondary:hover {
  background-color: #999;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  /* Adjust font sizes for smaller devices */
  #services{
    
  }
  .colored-heading {
    font-size: 18px;
    margin-bottom: 30px;
  }
  
  .service-icon {
    font-size: 36px;
    margin-bottom: 15px;
  }
  
  .service-title {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  .learn-more-button {
    font-size: 14px;
    padding: 8px 16px;
  }
  
  .modal-title {
    font-size: 24px;
  }
  
  .modal-body {
    font-size: 14px;
  }
}


