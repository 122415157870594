.contact-text-section {
    position: relative;
    margin: 20px auto;
    height: 120px;
    background-color: #01579e;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #001e3f;
  }

  .contact-text-section h2{
    font-weight: 800;
    color: white;
    font-family: 'Poppins', sans-serif;

  }
