/* Apply Times New Roman font family to all elements */
* {
  font-family: 'Times New Roman', Times, serif;
}

/* Carousel container */
.service-carousel {
  width: 100%;
  opacity: 2.3;
}

/* Carousel images */
.service-carousel-item img {
  width: 100%;
  height: 650px; /* Adjust the height as needed */
  object-fit: cover; /* Ensure the image covers the entire carousel item */
}

/* Carousel caption */
.service-carousel-caption {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background-color: #1A76D1; /* Semi-transparent black background */
  padding: 10px;
  border-radius: 5px;
}

/* Carousel caption heading */
.service-carousel-caption h3 {
  color: #fff; /* White text color */
  font-family: 'Roboto', sans-serif;
}

/* Carousel caption paragraph */
.service-carousel-caption p {
  color: #fff; /* White text color */
  font-size: 16px; /* Example font size */
  margin-top: 5px; /* Example margin */
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  /* Adjust font size for smaller screens */
  .service-carousel-item img{
    height: 450px;
  }

  .service-carousel-caption h3 {
    font-size: 24px;
  }
  
  .service-carousel-caption p {
    font-size: 14px;
  }
}

@media screen and (max-width: 480px) {
  /* Further adjust font size for even smaller screens */
  .service-carousel-item img{
    height: 350px; 
  }
  .service-carousel-caption h3 {
    font-size: 12px;
  }
  
  .service-carousel-caption p {
    font-size: 12px;
  }
}
