/* Blog.css */

/* Container styles */
.blog-card-carousel-container {
  max-width: 1200px;
  margin: 30px auto;
  padding: 10px;
}

/* Heading styles */
.blog-card-carousel-container h1 {
  color: #01759E;
  font-weight: 700;
  margin: 20px auto;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

/* Card carousel styles */
.blog-card-carousel {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

/* Individual blog card styles */
.blog-card {
  background-color: #ddd;
  flex: 0 0 auto;
  width: 390px; /* Adjust card width as needed */
  margin-right: 20px;
  border-radius: 10px;
  overflow: hidden;
  scroll-snap-align: start;
  display: flex; /* Add display:flex */
  flex-direction: column; /* Make children stack vertically */
  justify-content: center; /* Center vertically */
}

/* Blog card image styles */
.blog-card img {
  width: 100%;
  height: 250px; /* Set image height as needed */
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

/* Blog card content styles */
.blog-card-content {
  padding: 15px;
  text-align: center; /* Center text horizontally */
}

/* Blog card heading styles */
.blog-card-content h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #01759E;
  font-family: 'Poppins', sans-serif;
}

/* Blog card paragraph styles */
.blog-card-content p {
  font-size: 1.2rem;
  color: #7a7a7a;
  font-family: 'Poppins', sans-serif;
}

/* Responsive design */
@media screen and (max-width: 768px) {
  /* Adjust card width for smaller screens */
  .blog-card {
    width: calc(50% - 20px); /* Adjust card width for smaller screens */
    margin-right: 10px;
  }
}

@media screen and (max-width: 480px) {
  /* Further adjustments for even smaller screens */
  .blog-card {
    width: calc(120% - 20px); /* Adjust card width for even smaller screens */
    margin-right: 0;
  }

  /* Adjust font sizes for smaller screens */
  .blog-card-content h2 {
    font-size: 1.3rem; /* Adjust font size for smaller screens */
  }

  .blog-card-content p {
    font-size: 1rem; /* Adjust font size for smaller screens */
  }
}
