/* Common styles */
* {
  font-family: 'Montserrat', sans-serif;
}

.local-slider-container {
  margin: 0 auto; /* Center the container horizontally */
  max-width: 1200px; /* Set max-width for the container */
  padding: 0 20px; /* Add padding to the container */
}

.local-slider-container h2 {
  font-weight: 700;
  color: #01579e;
  text-align: center;
  padding: 20px;
}

.localpart-image-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin: 30px;
  text-align: center;
  width: calc(33.33% - 20px); /* Adjust width for three columns */
  max-width: 300px; /* Limit max width for responsiveness */
}

.localpart-card-image {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.localpart-card-title {
  margin: 20px 0;
  font-size: 1.2rem;
  font-weight: 700;
}

.slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  font-size: 24px; /* Adjust arrow size */
  color: black; /* Black color for arrows */
  cursor: pointer;
}

.arrow-slick-prev {
  left: 0px; /* Position the prev arrow */
}

.arrow-slick-next {
  right: 20px; /* Position the next arrow */
}

.arrow-icon {
  position: absolute;
  left: 0px;
  font-size: 24px; /* Adjust icon size */
  color: #fff; /* Black color for icons */
  border-radius: 100%;
  width: 30px;
  height: 30px;
  background-color: #01579e;
}



.view-more-button {
  background-color: #0078d0;
  border: 0;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: system-ui,-apple-system,system-ui,"Segoe UI",Roboto,Ubuntu,"Helvetica Neue",sans-serif;
  font-size: 18px;
  font-weight: 600;
  outline: 0;
  padding: 16px 21px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all .3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}
.view-more-button::before{
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 125px;
  content: "";
  height: 50%;
  left: 4%;
  opacity: .5;
  position: absolute;
  top: 0;
  transition: all .3s;
  width: 92%;
}

.view-more-button:hover {
  box-shadow: rgba(255, 255, 255, .2) 0 3px 15px inset, rgba(0, 0, 0, .1) 0 3px 5px, rgba(0, 0, 0, .1) 0 10px 13px;
  transform: scale(1.05);
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .localpart-image-card {
    width: calc(50% - 20px); /* Adjust width for two columns */
  }
  .local-slider-container h2 {
    font-size: 24px; /* Adjust font size for smaller screens */
  }
  .arrow-icon {
    font-size: 20px; /* Adjust arrow icon size for smaller screens */
    width: 25px;
    height: 25px;
  }
  .view-more-button {
    width: 100%; /* Make button width 100% for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .localpart-image-card {
    width: calc(100% - 20px); /* Adjust width for single column */
    margin: 20px 0; /* Adjust margin for single column */
  }
  .local-slider-container h2 {
    font-size: 20px; /* Adjust font size for even smaller screens */
  }
  .arrow-icon {
    font-size: 10px; /* Adjust arrow icon size for even smaller screens */
    width: 13px;
    height: 20px;
  }
  .view-more-button {
    font-size: 2px; /* Adjust font size for even smaller screens */
  }
}
