/* Navbar.css */
/* Basic styles for the navbar */
*{
    font-family: 'Poppins', sans-serif;
  }
  .navbar {
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    position: sticky;
    top: 0;
    height: 95px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 999;
    }
  
  
    .mail-icon {
      margin-left: 50px; /* Adjust the spacing between the last link and the mail icon */
     
      
    }
    .mail-icon a{
      position: absolute;
      top: 8px;
      right: 10px;
      color: #333; /* Set the color of the mail icon */
      font-size: 50px; /* Set the font size of the mail icon */
      text-decoration: none; /* Remove underline from the mail icon */
      list-style:none;
    
    } 
    .icon-mail{
      font-size: 30px;
      
    }
  
    .mail-icon a:hover {
      color: #007bff; /* Change color on hover */
    }
    
    
    .logo img{
      height: 75px;
      margin: 12px 5px 15px 25px;
    }
    
    /* Hamburger menu styles */
    .menu-toggle {
      display: none;
      flex-direction: column;
      cursor: pointer;
      margin-right: 20px;
    }
    
    .menu-toggle.open .bar:nth-child(1) {
      transform: translateY(4px) rotate(45deg);
    }
    
    .menu-toggle.open .bar:nth-child(2) {
      opacity: 0;
    }
    
    .menu-toggle.open .bar:nth-child(3) {
      transform: translateY(-4px) rotate(-45deg);
    }
    
    .menu-toggle .bar {
      width: 20px;
      height: 3px;
      background-color: #01579e;
      margin: 4px 0;
      transition: 0.4s;
    }
    
    /* Navigation links styles */
    .nav-links {
      list-style-type: none;
      display: flex;
      gap: 5px;
      padding: 10px;
    }
    .nav-links li{
      padding: 15px;
    }
   
    .nav-links li a {
      text-decoration: none;
      color: black; /* Change this to your desired text color */
      position: relative;
      font-size: 16px;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-weight: 600;

    }
  
    .nav-links li a::after {
      content: '';
      position: absolute;
      bottom: -8px; /* Adjust the position of the underline */
      left: 0;
      width: 0;
      height: 2px; /* Adjust the thickness of the underline */
      background-color: #01579e;/* Change this to your desired underline color */
      transition: width 0.3s ease;
    }
    
    .nav-links li a:hover::after {
      width: 100%; /* Expand the underline on hover */
    }
    
    .nav-links.open {
      display: flex;
      flex-direction: column;
      width: 100%;
      text-align: center;
      position: absolute;
      top: 60px; /* Adjust as needed */
      left: 0;
      background-color: #fff;
      padding: 10px 0;
      z-index: 1;
    }
    
    .nav-links.open li {
      margin: 10px 0;
    }
    
    .nav-links.open a {
      color: black;
      text-decoration: none;
      font-size: 16px;
    }
  
    .dropdown {
      position: relative;
    }
  
    .dropdown-icon {
      margin-left: 5px; /* Adjust margin as needed */
      transition: transform 0.3s; 
      color: #7a7a7a;
    }
  
    .dropdown.open .dropdown-icon {
      transform: rotate(180deg);
    }
    
    .dropdown span{
      text-decoration: none;
      color: black; /* Change this to your desired text color */
      position: relative;
      font-size: 16px;
      font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
      font-weight: 600;


    }
    
    .dropdown span::after{
      content: '';
      position: absolute;
      bottom: -8px; /* Adjust the position of the underline */
      left: 0;
      width: 0;
      height: 2px; /* Adjust the thickness of the underline */
      background-color: #01579e; /* Change this to your desired underline color */
      transition: width 0.3s ease;
    }
  
    .dropdown span:hover::after {
      width: 100%; /* Expand the underline on hover */
    }
    
    .dropdown-menu {
      position: absolute;
      top: 100%;
      width: 250px;
      text-align: center;
      left: 0;
      background-color: #fff;
      border: 0px solid #ccc;
      border-top: none;
      list-style: none;
      padding: 0;
      margin: 10px;
      display: none;
      z-index: 0;
    }
    
    .dropdown-menu li {
      padding: 10px;
    }
    
    .dropdown-menu li a {
      text-decoration: none;
      display: block;
      color: black;
    }
    
    .dropdown:hover .dropdown-menu {
      display: block;
    }
  
    .contact-info {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .contact-info .phone-icon,
    .contact-info .mail-icon {
      margin-right: 20px;
    }
    
    /* Social icons */
   
    
    .social-icons a {
      color: #fff; /* Change social icon color as needed */
      margin-right: 10px;
    }
    
    
    /* Media query for responsive design */
    @media screen and (max-width: 768px) {
      .menu-toggle {
        display: flex;
      }
    
      .nav-links {
        display: none;
      }
      .logo img{
        height: 40px;
      }
    }
    