/* CSS for .tourism-text-section */

.tourism-text-section {
   position: relative;
   margin: 20px auto;
   height: 120px; /* Initial height for the text section */
   background-color: #01579e; /* Background color */
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   display: flex;
   justify-content: center;
   align-items: center;
   color: #001e3f; /* Text color */
 }
 
 .tourism-text-section h2 {
   font-family: 'Poppins', sans-serif;
   font-weight: 800;
   font-size: 30px;
   color: #fff; /* Heading text color */
 }
 
 /* Media queries for responsiveness */
 @media screen and (max-width: 768px) {
   .tourism-text-section {
     height: 100px; /* Decrease height for smaller screens */
   }
   .tourism-text-section h2 {
     font-size: 24px; /* Decrease font size for smaller screens */
   }
 }
 
 @media screen and (max-width: 480px) {
   .tourism-text-section {
     height: 80px; /* Further decrease height for even smaller screens */
   }
   .tourism-text-section h2 {
     font-size: 20px; /* Further decrease font size for even smaller screens */
   }
 }
 