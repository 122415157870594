/* AboutHeroSection.css */

.about-hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.about-hero-content {
  max-width: 800px; /* Adjust as needed */
  text-align: center;
  margin-bottom: 20px;
}

.about-hero-content h1 {
  font-size: 2rem;
  color: #01759E;
}

.about-hero-content p {
  font-size: 18px;
  margin-bottom: 20px;
  color: #7a7a7a;
}

.about-hero-image img {
  width: auto;
  height: 400px; /* Increase the height as needed */
  max-width: 100%; /* Ensure the image does not exceed its container */
}

/* Media queries for responsiveness */
@media screen and (min-width: 768px) {
  .about-hero-section {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }

  .about-hero-content {
    margin-right: 20px;
    text-align: left;
  }

  .about-hero-image {
    flex: 1;
  }
  .about-hero-content p{
    font-size: 16px;
  }
}



