/* Counting.css */

/* Base font family */
* {
  font-family: 'Times New Roman', Times, serif;
}

/* Container styles */
.counting-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Heading and paragraph styles */
.counting-container h1,
.counting-container p {
  color: #fff;
  font-family: 'Poppins', sans-serif;
}

/* Count cards container styles */
.count-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* Individual count card styles */
.count-card {
  width: calc(25% - 20px);
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background-color: #1A76D1;
}

.count-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.count-card span {
  font-size: 2rem;
  color: #fff;
  font-weight: bold;
}

.count-card p {
  margin-top: 10px;
  font-size: 1rem;
  color: #fff;
}

/* Icon circle styles */
.icon-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
}

.icon-circle i {
  font-size: 2rem;
  color: #1A76D1;
}

/* Responsive Styles */
@media screen and (max-width: 992px) {
  /* Adjust count card width for smaller screens */
  .count-card {
      width: calc(50% - 20px);
  }
}

@media screen and (max-width: 768px) {
  /* Further adjust count card width for even smaller screens */
  .count-card {
      width: calc(100% - 20px);
  }
  
  /* Adjust font sizes for smaller screens */
  .count-card span {
      font-size: 1.5rem;
  }
  
  .count-card p {
      font-size: 0.9rem;
  }
}
