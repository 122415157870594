/* localcardSection.css */

.internationalcard-section {
    display: grid;
    grid-template-columns: 40% 60%; /* Adjust column widths */
    justify-content: space-between;
    align-items: center;
    max-width: 1170px;
    margin: 20px auto;
  }
  
  .internationalcard-image {
    /* 60% width */
    margin-right: 20px; /* Adjust margin as needed */
    transition: transform 0.3s ease;
  }
  
  .internationalcard-image img {
    max-width: 100%;
    height: auto;
  }
  
  .internationalcard-image img:hover {
    transform: scale(0.8); /* Increase scale to zoom in */
  }
  
  .internationalcard-content {
    /* 40% width */
  }
  
  .internationalcard-content h2 {
    font-size: 25px;
    margin-bottom: 20px;
    font-weight: 700;
    color: #01579e;
    font-family: 'Poppins', sans-serif;

  }
  
  .internationalcard-content h4{
    font-size: 16px;
    font-family: 'Poppins', sans-serif;

    
  }
  .internationalcard-content p {
    font-size: 16px;
    line-height: 1.6;
    color: #7a7a7a;
    font-family: 'Poppins', sans-serif;

  }

  .toggle-button {
    align-items: center;
  appearance: none;
  background-image: radial-gradient(100% 100% at 100% 0, #5adaff 0, #5468ff 100%);
  border: 0;
  border-radius: 6px;
  box-shadow: rgba(45, 35, 66, .4) 0 2px 4px,rgba(45, 35, 66, .3) 0 7px 13px -3px,rgba(58, 65, 111, .5) 0 -3px 0 inset;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
  }
  .toggle-button:focus{
    box-shadow: #3c4fe0 0 0 0 1.5px inset, rgba(45, 35, 66, .4) 0 2px 4px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;

  }
  .toggle-button:active{
    box-shadow: #3c4fe0 0 3px 7px inset;
    transform: translateY(2px);
  }
  
  .toggle-button:hover {
    box-shadow: rgba(45, 35, 66, .4) 0 4px 8px, rgba(45, 35, 66, .3) 0 7px 13px -3px, #3c4fe0 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  
  /* Responsive layout adjustments */
  @media (max-width: 768px) {
    .internationalcard-section {
      grid-template-columns: 100%; /* Single column layout on smaller screens */
    }
  
    .internationalcard-image {
      margin-right: 0; /* Remove right margin on smaller screens */
      margin-bottom: 20px; /* Add bottom margin for spacing */
    }
  }
  